'use client';

import { BsCurrencyExchange } from 'react-icons/bs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRates, selectCurrency, selectRates, setCurrency } from '@/store/generalSlice';

export default function Currency() {
  const dispatch = useDispatch();
  const currency = useSelector(selectCurrency);

  const handleCurrencyChange = (newCurrency: string) => {
    dispatch(setCurrency(newCurrency));
  };

  useEffect(() => {
    const test = async () => {
      dispatch(setCurrency('USD'));
      dispatch(fetchRates() as any);
    }
    test();
  }, [dispatch]);

  return (
    <div className="group relative">
      <span className="text-white flex items-center gap-2"><BsCurrencyExchange /> {currency}</span>
      <div className="z-20 absolute -left-1 bg-gray-950 py-2 px-10 hidden group-hover:block">
        {currency !== 'USD' && <button
            type="button"
            onClick={() => handleCurrencyChange('USD')}
            className="hover:text-slate-500 uppercase tracking-widest text-white">
            USD
        </button>}
        {currency !== 'EUR' && <button
            type="button"
            onClick={() => handleCurrencyChange('EUR')}
            className="hover:text-slate-500 uppercase tracking-widest text-white">
            EUR
        </button>}
        {currency !== 'RUB' && <button
            type="button"
            onClick={() => handleCurrencyChange('RUB')}
            className="hover:text-slate-500 uppercase tracking-widest text-white">
            RUB
        </button>}
      </div>
    </div>
  )
}