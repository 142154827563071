'use client';

import Link from 'next/link';
import { signIn, signOut } from 'next-auth/react';
import { useSession } from 'next-auth/react';
import { useState } from 'react';
import { FaDiscord, FaSteam } from 'react-icons/fa';

import { cn } from '@/lib/utils';

import Currency from '@/components/Currency';
import Button from '@/components/ui/buttons/Button';
import UnderlineLink from '@/components/ui/links/UnderlineLink';

import Container from '@/layout/Container';

import Logo from '~/svg/Logo-Full.svg';
import Beta from '~/svg/Beta.svg';

export default function Header() {
  const session: any = useSession();
  const [isMobileMenu, showMobileMenu] = useState(false);
  const auth = session.status === 'authenticated';
  const user = session.data?.user;

  const handleSignIn = () => {
    return signIn('steam');
  };

  const handleLogout = () => {
    return signOut();
  };

  return (
    <div className="w-full bg-gray-950 py-7">
      <Container>
        <div className="flex items-center justify-between gap-8 md:gap-16">
          <Link href="/" className="block relative">
            <Logo className="block w-full h-6" />
            <Beta className="absolute right-1 top-[calc(100%+0.25rem)] h-5" />
          </Link>
          <div className="hidden items-center justify-start gap-8 xl:flex">
            <div className="flex items-start justify-start gap-8">
              <UnderlineLink
                href="/"
                className="uppercase tracking-widest text-white"
              >
                Home
              </UnderlineLink>

              <UnderlineLink
                href="/profile"
                className="uppercase tracking-widest text-white"
              >
                Profile
              </UnderlineLink>

              <UnderlineLink
                href="/sell"
                className="uppercase tracking-widest text-white"
              >
                Sell
              </UnderlineLink>

              <div className="group relative">
                <span className="uppercase tracking-widest text-white cursor-pointer">Pages</span>
                <div className={cn(
                  'absolute left-0 bg-gray-950 w-[165px] bg-opacity-70 flex-col gap-2 p-4 shadow-md',
                  'hidden group-hover:flex z-20',
                )}>
                  {auth && <UnderlineLink
                      href="/gift-cards"
                      className="uppercase tracking-widest text-white"
                  >
                      Gift Cards
                  </UnderlineLink>}

                  <UnderlineLink
                    href="/#how-to-sell"
                    className="uppercase tracking-widest text-white"
                  >
                    How to sell
                  </UnderlineLink>

                  <UnderlineLink
                    href="/#faq"
                    className="uppercase tracking-widest text-white"
                  >
                    FAQ
                  </UnderlineLink>

                  {<UnderlineLink
                      href="/giveaways"
                      className="uppercase tracking-widest text-white"
                  >
                      Giveaways
                  </UnderlineLink>}
                </div>
              </div>
            </div>
          </div>

          <div className="items-center justify-start gap-8 sm:hidden relative">
            <Button className="px-10" onClick={() => showMobileMenu(!isMobileMenu)} variant="primary">
              Menu
            </Button>
            {isMobileMenu && <div className="z-30 flex flex-col gap-8 -left-20 absolute bg-black p-10">
              <UnderlineLink
                href="/"
                className="uppercase tracking-widest text-white"
              >
                Home
              </UnderlineLink>

              <UnderlineLink
                href="/profile"
                className="uppercase tracking-widest text-white"
              >
                Profile
              </UnderlineLink>

              <UnderlineLink
                href="/sell"
                className="uppercase tracking-widest text-white"
              >
                Sell
              </UnderlineLink>

              {auth && <UnderlineLink
                href="/gift-cards"
                className="uppercase tracking-widest text-white"
              >
                Gift Cards
              </UnderlineLink>}
              <UnderlineLink
                href="/#how-to-sell"
                className="uppercase tracking-widest text-white"
              >
                How to sell
              </UnderlineLink>

              <UnderlineLink
                href="/#faq"
                className="uppercase tracking-widest text-white"
              >
                FAQ
              </UnderlineLink>

              <UnderlineLink
                href="/giveaways"
                className="uppercase tracking-widest text-white"
              >
                Giveaways
              </UnderlineLink>

            {!auth ? (
              <Button
                onClick={handleSignIn}
                variant="primary"
              >
                Sign In
              </Button>
            ) : (
              <Button onClick={handleLogout} variant="primary">
                Logout
              </Button>
            )}
            </div>}
          </div>

          <div className="hidden sm:flex items-center justify-start gap-5">
            <Currency />
            <a href="https://discord.gg/rC8Swqg35y" target="_blank" className="block">
              <Button variant="dark" leftIcon={FaDiscord} className="hidden sm:flex">
                Discord
              </Button>
            </a>
            {!auth ? (
              <Button
                onClick={handleSignIn}
                variant="primary"
                leftIcon={FaSteam}
              >
                Sign In
              </Button>
            ) : (
              <Button onClick={handleLogout} variant="primary">
                <div>
                  <img
                    src={`${user?.image}`}
                    className="h-6 w-6 rounded-full"
                    alt=""
                  />
                </div>
                Logout
              </Button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
