import { ReactNode } from 'react';

import { cn } from '@/lib/utils';

export default function Container({
  children,
  className,
}: {
  children: ReactNode;
  className?: any;
}) {
  return (
    <div
      className={cn('mx-auto w-full max-w-7xl px-5 xl:px-0', className)}
    >
      {children}
    </div>
  );
}
