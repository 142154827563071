import { RootState } from '@/store/index';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

type GeneralState = {
  rates: any;
  currency: string;
};

const initialState: GeneralState = {
  rates: {},
  currency: 'USD',
};

export const fetchRates = createAsyncThunk<void, void>('currency/fetchRates', async () => {
  const res = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
  const data = await res.json();
  return data;
});

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setRates: (state, action) => {
      state.rates = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRates.fulfilled, (state, action: PayloadAction<any>) => {
        Object.assign(state.rates, action.payload.rates);
      })
  },
});


export const { setCurrency } = generalSlice.actions;
export const { setRates } = generalSlice.actions;
export const selectCurrency = (state: RootState) => state.generalSlice.currency;
export const selectRates = (state: RootState) => state.generalSlice.rates;

export default generalSlice.reducer;